import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"

const WorkIndex = ({ data }) => {
  const { edges: posts } = data.allMdx

  return (
    <Wrapper>
      {posts.map(({ node: post }) => (
        <div key={post.id}>
          <Link to={post.fields.slug}>
            <h2>{post.frontmatter.title}</h2>
          </Link>
          <p>{post.excerpt}</p>
        </div>
      ))}
    </Wrapper>
  )
}

export const pageQuery = graphql`
  query workIndex {
    allMdx(
      filter: {
        fields: { postType: { eq: "project" } }
        frontmatter: { draft: { eq: false } }
      }
    ) {
      edges {
        node {
          id
          excerpt
          frontmatter {
            title
          }

          fields {
            slug
          }
        }
      }
    }
  }
`

const Wrapper = styled.div`
  padding: calc(var(--gridMarginGap) * 3) var(--gridMarginGap);
`

export default WorkIndex
